<template>
  <div>
    <b-card
      :title="$t('Transaction')"
    >
      <b-form
        style="max-width: 1024px;"
        @submit.prevent
      >
        <b-row class="mt-1 text-left text-md-center align-items-top align-content-start">
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="ยูสเซอร์เนม"
              label-for="mc-username"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <b-form-input
                id="mc-username"
                v-model="form.username"
                placeholder="Username"
                @keydown.enter="onSubmit"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="Round Id"
              label-for="RoundId"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <b-form-input
                id="RoundId"
                v-model="form.roundId"
                placeholder="Round Id"
                @keydown.enter="onSubmit"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="วันที่ออกบิล"
              label-for="mc-start"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <flat-pickr
                v-model="startDate"
                class="form-control"
                :config="{ disableMobile:true,enableTime: true,dateFormat: 'd/m/Y H:i:S' , enableSeconds:true , time_24hr:true}"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="ถึงวันที่"
              label-for="mc-ebd"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <flat-pickr
                v-model="endDate"
                class="form-control"
                :config="{ disableMobile:true,enableTime: true,dateFormat: 'd/m/Y H:i:S' , enableSeconds:true , time_24hr:true}"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click.prevent="onSubmit"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card
      title=""
    >
      <b-table
        responsive
        :fields="columns"
        :per-page="pagination.rowsPerPage"
        :current-page="pagination.page"
        :items="onRequest"
        :filter="filter"
        bordered
      >
        <template #cell(username)="v">
          <span
            style="text-decoration: underline;cursor: pointer"
            class="font-weight-bolder"
            @click="showUserDetial(v.value)"
          >
            {{ v.value }}
          </span>
        </template>
        <template #cell(type)="v">
          <span
            class="font-weight-bolder"
          >
            {{ v.value }}
          </span>
        </template>
        <template #cell(amount)="v">
          <span
            class="font-weight-bolder"
          >
            {{ v.value }}
          </span>
        </template>
        <template #cell(status)="v">
          <div>
            <b-button
              v-if="v.value === -1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="cyan-7"
            >Refund
            </b-button>
            <b-button
              v-else-if="v.value === 0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="secondary"
              @click="refundBet(v.item)"
            >Waiting
            </b-button>
            <b-button
              v-else-if="v.value === 1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="success"
            >Win
            </b-button>
            <b-button
              v-else-if="v.value === 2"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="danger"
            >Lose
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="2"
        >
          <b-form-group class="text-nowrap">
            <label class="d-inline-block  mr-50 text-sm-right font-small-4">Per page</label>
            <b-form-select
              v-model="pagination.rowsPerPage"
              size="sm"
              :options="rowsOptions"
              class="w-50"
              style="min-width: 100px"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="8"
          md="6"
          sm="12"
        >
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.rowsNumber"
            :per-page="pagination.rowsPerPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable vue/no-template-shadow,vue/no-unused-components */
import {
  BFormSelect,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BRow,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import loggingService from '@/service/loggingService'

export default {
  components: {
    flatPickr,
    BTable,
    BCardBody,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormDatepicker,
    BPagination,
    BFormSelect,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        username: '',
        roundId: '',
      },
      rowsOptions: [1, 5, 10, 15, 25, 50, 100, 250, 500, 'all'],
      type: '',
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 10,
      },
      columns: [
        {
          key: 'index',
          label: 'ลำดับ',
          class: 'text-center ',
          thStyle: {
            'max-width': '60px',
            'padding-left': '5px',
            'padding-right': '5px',
          },
        },
        {
          key: 'createAt',
          label: 'วัน/เวลา',
          class: 'text-nowrap',
        },
        {
          key: 'prefix',
          label: 'Prefix',
          class: 'text-nowrap text-right',
        },
        {
          key: 'username',
          label: 'Username',
          class: 'text-nowrap',
        },
        {
          key: 'type',
          label: 'Type',
          class: 'text-nowrap',
        },
        {
          key: 'before',
          label: 'Before',
          class: 'text-nowrap',
          formatter: v => this.$store.state.app.toNumber(v.toFixed(2)),
        },
        {
          key: 'amount',
          label: 'Amount',
          class: 'text-nowrap',
          formatter: v => this.$store.state.app.toNumber(v.toFixed(2)),
        },
        {
          key: 'after',
          label: 'After',
          class: 'text-nowrap',
          formatter: v => this.$store.state.app.toNumber(v.toFixed(2)),
        },
        {
          key: 'betId',
          label: 'BetId',
          class: 'text-nowrap',
        },
        {
          key: 'roundId',
          label: 'RoundId',
          class: 'text-nowrap',
        },
        {
          key: 'refId',
          label: 'RefId',
          class: 'text-nowrap',
        },
      ],
      data: [],
      startDate: this.$store.state.app.DateFormat(new Date(new Date().setHours(0, 0, 0, 0))),
      endDate: this.$store.state.app.DateFormat(new Date()),
      filter: '',
      summation: {
        betAmount: 0,
        winLose: 0,
        rollingAmount: 0,
      },
    }
  },
  beforeMount() {
    this.filter = `&startDate=${this.startDate}&endDate=${this.endDate}`
  },
  async mounted() {
    if (this.$route.query.id !== undefined) {
      this.username = this.$route.query.id
    }
  },
  methods: {
    onSubmit() {
      const { username, roundId } = this.form

      this.filter = `&startDate=${this.startDate}&endDate=${this.endDate}`
      if (username !== '') {
        this.filter += `&username=${username}`
      }

      if (roundId !== '') {
        this.filter += `&round=${roundId}`
      }
    },
    // eslint-disable-next-line consistent-return
    async onRequest() {
      let {
        // eslint-disable-next-line prefer-const
        page,
        rowsPerPage,
        // eslint-disable-next-line prefer-const
        rowsNumber,
        // eslint-disable-next-line prefer-const
        sortBy,
        // eslint-disable-next-line prefer-const
        descending,
      } = this.pagination

      if (rowsPerPage === 'all') {
        rowsPerPage = 0
      }
      const startRow = (page - 1) * rowsPerPage
      const fetchCount = rowsPerPage === 0 ? rowsNumber : rowsPerPage

      this.loading = true

      const res = await loggingService.getAuthen(`transaction?limit=${fetchCount}&page=${startRow}${this.filter}`)
      if (res.status === 200) {
        this.data = []
        this.summation = {
          betAmount: 0,
          winLose: 0,
          rollingAmount: 0,
        }

        const { list } = res.data
        let index = startRow + 1

        res.data.list.forEach((v, i) => {
          this.summation.betAmount += v.betAmount
          this.summation.winLose += v.winLose
          this.summation.rollingAmount += v.rollingAmount

          // list[i].username += `@${v.agent}`
          list[i].index = index
          index += 1
        })

        this.pagination.page = page
        this.pagination.rowsPerPage = rowsPerPage
        this.pagination.rowsNumber = res.data.count
        this.pagination.sortBy = sortBy
        this.pagination.descending = descending
        this.loading = false

        return res.data.list
      }
    },
    showUserDetial(username) {
      this.$router.push(`members/edit?id=${username}`)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
